import Paragraph from "../../components/Paragraph/Paragraph";
import Headline from "../../components/Headline/Headline";
import RatingBox from "../../components/RatingBox/RatingBox";

import background from "../../assets/background.jpg";

function End() {
  return (
    <div
      style={{ backgroundImage: `url("${background}")` }}
      className={`min-h-screen h-full lg:h-screen w-screen bg-center bg-cover 3xl:p-16 lg:p-8 p-4 pt-16 text-white font-mono flex flex-row items-center justify-center gap-4 xl:gap-12`}
    >
      <main
        className={`
      h-full w-full flex flex-col items-center justify-center p-8 bg-my-red-half border-solid border-2 border-my-red rounded-xl`}
      >
        <Headline className="uppercase mb-2 text-center" size="xl">
          Das Ende
        </Headline>
        <Headline className="mb-4 text-center" size="m">
          Danke fürs Mitspielen
        </Headline>
        <Paragraph className="mb-4 text-center"> Ein Spiel von Deniz Binay und Jannis Tanner im Jahr 2022. </Paragraph>
        <a className="mb-8 underline text-center" target="_blank" rel="noreferrer" href="https://www.bombig.net">
          www.bombig.net
        </a>
        <hr className="border-1 w-1/2 mb-8" />
        <RatingBox />
      </main>
    </div>
  );
}

export default End;
