import { useNavigate } from "react-router-dom";

import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

import intro from "../../assets/intro.mp4";

function Intro() {
  const navigate = useNavigate();

  function onEnd() {
    navigate("/game");
  }

  return (
    <div>
      <VideoPlayer video={intro} onEnd={onEnd} />
    </div>
  );
}

export default Intro;
