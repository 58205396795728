function createTimeCode() {
  const time = new Date();
  let hours = time.getHours();
  let minutes = time.getMinutes();

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;

  return `${hours}:${minutes}`;
}

export default createTimeCode;
