import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JSConfetti from "js-confetti";

import { useContext } from "react";
import CodeContext from "../../utils/codeContext.js";

import deactivateCode from "../../api/deactivateCode/deactivateCode.js";

import Paragraph from "../../components/Paragraph/Paragraph";
import Headline from "../../components/Headline/Headline";
import Divider from "../../components/Divider/Divider";
import Button from "../../components/Button/Button";

import CodeInput from "./CodeInput";

let oneFirstCorrect = false;
let twoFirstCorrect = false;
let threeFirstCorrect = false;

function Code() {
  const navigate = useNavigate();
  const jsConfetti = new JSConfetti();

  const context = useContext(CodeContext);

  const [code, setCode] = useState({
    first: {
      value: "",
      isCorrect: false,
    },
    second: {
      value: "",
      isCorrect: false,
    },
    third: {
      value: "",
      isCorrect: false,
    },
    fourth: {
      value: "",
      isCorrect: false,
    },
  });

  useEffect(() => {
    let first = context.gameCodes.first;
    let second = context.gameCodes.second;
    let third = context.gameCodes.third;
    let fourth = context.gameCodes.fourth;

    setCode({
      first: {
        value: first || "",
        isCorrect: first ? true : false,
      },
      second: {
        value: second || "",
        isCorrect: second ? true : false,
      },
      third: {
        value: third || "",
        isCorrect: third ? true : false,
      },
      fourth: {
        value: fourth || "",
        isCorrect: fourth ? true : false,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (isNaN(value) || value.length > 4) {
      return;
    }
    const prevIsCorrect = code[name].isCorrect;
    setCode({ ...code, [name]: { value: value, isCorrect: prevIsCorrect } });
  }

  async function handleCheckClick() {
    let codes = ["1337", "3301", "3141", "1851"];

    let first = null;
    let second = null;
    let third = null;
    let fourth = null;

    if (codes.includes(code.first.value)) {
      delete codes[codes.indexOf(code.first.value)];
      first = true;
      context.gameCodes.first = code.first.value;
      if (oneFirstCorrect === false) {
        oneFirstCorrect = true;
        firstCorrect();
      }
    } else {
      first = false;
      oneFirstCorrect = false;
      context.gameCodes.first = false;
      if (code.first.value && !first) {
        wrongCode();
      }
    }

    if (codes.includes(code.second.value)) {
      delete codes[codes.indexOf(code.second.value)];
      second = true;
      context.gameCodes.second = code.second.value;
      if (twoFirstCorrect === false) {
        twoFirstCorrect = true;
        firstCorrect();
      }
    } else {
      second = false;
      twoFirstCorrect = false;
      context.gameCodes.second = false;
      if (code.second.value && !second) {
        wrongCode();
      }
    }

    if (codes.includes(code.third.value)) {
      delete codes[codes.indexOf(code.third.value)];
      third = true;
      context.gameCodes.third = code.third.value;
      if (threeFirstCorrect === false) {
        threeFirstCorrect = true;
        firstCorrect();
      }
    } else {
      third = false;
      threeFirstCorrect = false;
      context.gameCodes.third = false;
      if (code.third.value && !third) {
        wrongCode();
      }
    }

    if (codes.includes(code.fourth.value)) {
      delete codes[codes.indexOf(code.fourth.value)];
      fourth = true;
      context.gameCodes.fourth = code.fourth.value;
    } else {
      fourth = false;
      context.gameCodes.fourth = false;
      if (code.fourth.value && !fourth) {
        wrongCode();
      }
    }

    const prevFirstValue = code.first.value;
    const prevSecondValue = code.second.value;
    const prevThirdValue = code.third.value;
    const prevFourthValue = code.fourth.value;

    setCode({
      first: { value: prevFirstValue, isCorrect: first },
      second: { value: prevSecondValue, isCorrect: second },
      third: { value: prevThirdValue, isCorrect: third },
      fourth: { value: prevFourthValue, isCorrect: fourth },
    });

    function firstCorrect() {
      jsConfetti.addConfetti({
        emojis: ["✅"],
        confettiNumber: 10,
      });
    }

    function wrongCode() {
      jsConfetti.addConfetti({
        emojis: ["❌"],
        confettiNumber: 10,
      });
    }

    if (first && second && third && fourth) {
      await deactivateCode(context.code);
      await jsConfetti.addConfetti({
        emojis: ["✅"],
        confettiNumber: 500,
      });
      navigate("/outro");
    }
  }

  return (
    <div>
      <Divider className="mb-4" />
      <Headline tag="h3" size="m" className="mb-2">
        Hier den Code zur Entschüsselung eingeben
      </Headline>
      <Paragraph className="mb-2">
        Die Codes bestehen aus 4 Ziffern. Die Reihenfolge der Codeblöcke ist irrelevant.
      </Paragraph>
      <div className="flex flex-row flex-wrap justify-between my-4">
        <CodeInput name="first" value={code.first} onChange={handleInputChange} />
        <CodeInput name="second" value={code.second} onChange={handleInputChange} />
        <CodeInput name="third" value={code.third} onChange={handleInputChange} />
        <CodeInput name="fourth" value={code.fourth} onChange={handleInputChange} />
      </div>
      <Button onClick={handleCheckClick} large className="w-full uppercase">
        Code Prüfen
      </Button>
    </div>
  );
}

export default Code;
