function CodeInput({ name, value, onChange }) {
  return (
    <div className="relative w-1/2 lg:w-1/4">
      <input
        className="w-full py-2 pr-4 text-xl text-my-red text-center border-solid border-4 border-my-red rounded-xl"
        type="text"
        name={name}
        value={value.value}
        onChange={onChange}
        placeholder="0000"
      />
      <div
        className={` ${
          value.isCorrect ? "" : "hidden"
        } flex justify-center items-center rounded-full w-6 h-6 bg-green-500 absolute right-4 top-4`}
      >
        ✔
      </div>
      <div
        className={` ${
          !value.isCorrect ? "" : "hidden"
        } flex justify-center items-center rounded-full w-7 h-7 bg-my-red absolute right-3 top-3`}
      >
        ✘
      </div>
    </div>
  );
}

export default CodeInput;
