import auth from "../auth.js";

const url = "https://bombig-escape.de/wp-json/lmfwc/v2/licenses/validate/";

async function validateCode(code) {
  const response = await fetch(url + code, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
    redirect: "follow",
  });
  const data = await response.json();
  const isSuccess = data.success;
  const remainingActivations = data.data.remainingActivations;

  if (isSuccess && remainingActivations > 0) {
    return "ok";
  } else if (isSuccess && remainingActivations === 0) {
    return "used";
  } else {
    return "invalid";
  }
}

export default validateCode;
