import { Routes, Route } from "react-router-dom";

import Welcome from "../../pages/Welcome/Welcome.js";
import Intro from "../../pages/Intro/Intro.js";
import Game from "../../pages/Game/Game.js";
import Outro from "../../pages/Outro/Outro.js";
import End from "../../pages/End/End.js";

import CodeContext from "../../utils/codeContext.js";
import { useState } from "react";

function App() {
  const [code, setCode] = useState("");
  const gameCodes = { first: false, second: false, third: false, fourth: false };

  return (
    <CodeContext.Provider value={{ code: code, setCode: setCode, gameCodes: gameCodes }}>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/game/*" element={<Game />} />
        <Route path="/outro" element={<Outro />} />
        <Route path="/end" element={<End />} />
      </Routes>
    </CodeContext.Provider>
  );
}

export default App;
