import { useState, useRef } from "react";
import Message from "./Message.js";
import Typing from "./Typing.js";

import createAnswer from "./createAnswer.js";
import createTimeCode from "./createTimeCode.js";

function Chat({ botQueue, setBotQueue, messages, setMessages }) {
  const messagesEndRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [userMessage, setUserMessage] = useState("");

  function addMessage(message) {
    scrollToBottom();
    setMessages([...messages, message]);
  }

  function handleInputKeyDown(event) {
    if (event.key === "Enter") {
      addMessage({
        text: userMessage,
        time: createTimeCode(),
        sender: "user",
      });
      setUserMessage("");
      setBotQueue(createAnswer(userMessage));
    }
  }

  function onHandleInputChange(event) {
    if (!isTyping) {
      setUserMessage(event.target.value);
    }
  }

  function scrollToBottom() {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }

  if (botQueue.length > 0 && !isTyping) {
    setIsTyping(true);

    const [first, ...rest] = botQueue;
    const delay = first.length * 25;

    setTimeout(() => {
      addMessage({
        text: first,
        time: createTimeCode(),
        sender: "bot",
      });
      setBotQueue(rest);
      setIsTyping(false);
    }, delay);
  }

  return (
    <div className="flex flex-col justify-center w-full h-full">
      <div className="bg-my-red rounded-xl w-full h-full p-4 lg:p-8 scrollbar-thin overflow-y-scroll scrollbar-thumb-red-900 scrollbar-track-red-400 lg:mb-8">
        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        <div className={`w-full flex justify-end items-center`}>
          <div> {isTyping ? <Typing /> : null} </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
      <div className={`bg-white text-black rounded-xl w-full ${isTyping ? "opacity-70" : ""}`}>
        <input
          className={`w-full rounded-xl p-4 ${isTyping ? "cursor-wait" : ""}`}
          value={userMessage}
          onChange={onHandleInputChange}
          onKeyDown={handleInputKeyDown}
          type="text"
          placeholder={`${isTyping ? "Warte auf die Antwort" : "Schreibe deine Nachricht..."}`}
        />
      </div>
    </div>
  );
}

export default Chat;
