import { useState } from "react";
import { Rating } from "react-simple-star-rating";

import Headline from "../../components/Headline/Headline";

export default function RatingBox() {
  const [hasRated, setHasRated] = useState(false);
  const [comment, setComment] = useState({
    Name: "",
    Rating: 100,
    Message: "",
    Game: "Hacktivism",
  });

  function onCommentChange(event) {
    setComment({ ...comment, [event.target.name]: event.target.value });
  }

  function onRatingChange(rating) {
    setComment({ ...comment, Rating: rating });
  }

  async function onSendClick(event) {
    setComment({ ...comment, Rating: comment.Rating });
    const payload = JSON.stringify(comment);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload,
    };
    const response = await fetch("https://bombig-escape.de/wp-json/bombig/v1/createcomment", requestOptions);
    const data = await response.json();
    setHasRated(true);
    console.log("response: ", data);
  }

  if (!hasRated) {
    return (
      <div className="lg:w-1/2 xl:w-1/3 w-full">
        <Headline className="mb-6 text-center uppercase" size="m">
          Kleine Rezession wäre ein träumchen!
        </Headline>

        <div className="flex sm:flex-row flex-col mb-2">
          <input
            onChange={onCommentChange}
            name="Name"
            value={comment.Name}
            className="mb-2 sm:mr-6 text-black p-2"
            type="text"
            placeholder="Name"
          />
          <div className="RatingStars mb-3 mt-2 md:mb-0 md:mt-0">
            <Rating
              onClick={onRatingChange}
              ratingValue={comment.Rating}
              size={30}
              fillColorArray={["#f17a45", "#f19745", "#f1a545", "#f1b345", "#f1d045"]}
            />
          </div>
        </div>
        <textarea
          onChange={onCommentChange}
          name="Message"
          value={comment.Message}
          className="text-black p-2 mb-2 w-full h-24"
          type="text"
          placeholder="Nachricht"
        />
        <button onClick={onSendClick} className="p-2 bg-black white">
          Senden
        </button>
      </div>
    );
  } else {
    return <Headline size="xl">Vielen Dank</Headline>;
  }
}
