import Headline from "../Headline/Headline";
import Paragraph from "../Paragraph/Paragraph";
import Code from "../Code/Code";

function Info() {
  return (
    <>
      <div className="h-full">
        <Headline tag="h1" size="xl" className="xl:mt-4 mb-6 xl:mb-8 3xl:mb-16 text-center">
          Dein gesamtes Gerät wurde verschlüsselt.
        </Headline>
        <Headline tag="h2" size="m" className="mb-2 xl:mb-4">
          Was ist passiert?
        </Headline>
        <Paragraph className="mb-4 xl:mb-8 3xl:mb-16">
          Du warst so einfältig, auf eine unserer Trick-Emails zu klicken. Du scheinst wirklich alles zu glauben. Weil
          solche Stumpfheit keine Technik verdient, haben wir dein Endgerät komplett verschlüsselt.
        </Paragraph>
        <Headline tag="h2" size="m" className="mb-2 xl:mb-4">
          Kann ich meine Daten wieder bekommen?
        </Headline>
        <Paragraph className="mb-4 xl:mb-8 3xl:mb-16">
          Eigentlich hast du schon längst bewiesen, dass du nicht würdig bist. Doch einen Ausweg halten wir bereit.
          Finde und löse alle Rätsel und beweise so deine Intelligenz.
        </Paragraph>
      </div>
      <Code />
    </>
  );
}

export default Info;
