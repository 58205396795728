import { useState, useRef } from "react";

import Play from "../../assets/play.svg";
import Pause from "../../assets/pause.svg";
import Back from "../../assets/back.svg";
import Forward from "../../assets/forward.svg";
import Back10 from "../../assets/back-10.svg";
import Forward10 from "../../assets/forward-10.svg";

function VideoPlayer({ video, onEnd }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [uiIsVisable, setUiIsVisable] = useState(true);
  const videoRef = useRef();

  function handlePlayPause() {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setTimeout(() => {
        setUiIsVisable(false);
      }, 3000);
      setIsPlaying(true);
    }
  }

  function handleBack() {
    videoRef.current.currentTime = 0;
  }

  function handleBack10() {
    videoRef.current.currentTime -= 10;
  }

  function handleForward() {
    onEnd();
  }

  function handleForward10() {
    videoRef.current.currentTime += 10;
  }

  return (
    <div className="bg-black min-h-screen text-white flex flex-col items-center justify-center">
      <div
        className={`fixed w-full h-full flex justify-center items-center gap-8 lg:gap-12 z-30 transition-all
            ${uiIsVisable ? "opacity-100" : "opacity-0 pointer-events-none"}`}
      >
        <img src={Back} onClick={handleBack} alt="back" className="w-4 lg:w-12 h-auto cursor-pointer" />
        <img src={Back10} onClick={handleBack10} alt="back 10 seconds" className="w-7 lg:w-16 h-auto cursor-pointer" />
        {isPlaying ? (
          <img src={Pause} alt="play" onClick={handlePlayPause} className="w-6 lg:w-16 h-auto cursor-pointer" />
        ) : (
          <img src={Play} alt="pause" onClick={handlePlayPause} className="w-6 lg:w-16 h-auto cursor-pointer" />
        )}
        <img
          src={Forward10}
          onClick={handleForward10}
          alt="forward 10 seconds"
          className="w-7 lg:w-16 h-auto cursor-pointer"
        />
        <img src={Forward} onClick={handleForward} alt="forward" className="w-4 lg:w-12 h-auto cursor-pointer" />
      </div>

      <video
        onClick={() => setUiIsVisable(true)}
        ref={videoRef}
        onEnded={onEnd}
        className="w-screen h-screen"
        preload="auto"
        src={video}
      ></video>
    </div>
  );
}

export default VideoPlayer;
