import Headline from "../Headline/Headline";
import Paragraph from "../Paragraph/Paragraph";

function Rules() {
  return (
    <div className="h-full">
      <Headline tag="h1" size="xl" className="mt-4 mb-16 text-center">
        Regeln
      </Headline>
      <Paragraph className="mb-4">
        Ziel des Spiels ist es, vier Codes herauszufinden und hier im Game Hub einzugeben. Die gesuchten Zahlen sind am
        Ende eines Rätselstrangs zu finden. Dabei kann es sein, dass du für die richtige Zahl im Internet recherchieren
        musst. Wenn du denkst, du hast die richtige Zahl herausgefunden, kannst du versuchen, sie im Game Hub
        einzugeben. Trau dich!
      </Paragraph>
      <Paragraph className="mb-4 font-bold">
        1. Du musst das komplette Internet zur Hilfe nehmen. Vor allem Google und Wikipedia sind deine Freunde.
      </Paragraph>
      <Paragraph className="mb-4 font-bold">
        2. Die Codes bestehen aus 4 Ziffern. Die Reihenfolge der Codeblöcke ist irrelevant.
      </Paragraph>
      <Paragraph className="mb-4 font-bold">
        3. In jedem Rätsel gibt es einen Hilfecode in eckigen Klammern. Der Hilfecode für diesen Spielbildschirm ist
        [HUB-1]. Gibt ihn unter "Hilfe" in den Chat ein und du wirst eine Antwort erhalten. Die Hilfecodes sind nie Teil
        des Rätsels.
      </Paragraph>
      <Paragraph className="mb-4">
        Du findest bei jedem Rätsel einen Hilfecode. Diese erkennst du daran, dass sie in eckigen Klammern angegeben
        ist. Beispiel: [XYZ-0]. Diesen Code kannst du unter „Hilfe“ im Game Hub in den Chat ohne die rechteckigen
        Klammern eingeben und so Tipps für das jeweilige Rätsel erhalten.
      </Paragraph>
      <Paragraph>
        Bei einigen Rätseln ist es aus technischen Gründen nicht möglich, einen Hilfecode zu platzieren. Wenn du also
        keinen Hilfecode direkt bei einem Rätsel findest, gib einfach die letzte Rätsel-ID ein, die dir angezeigt wurde.
      </Paragraph>
    </div>
  );
}

export default Rules;
