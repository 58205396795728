function createAnswer(question) {
  let answers;
  let simplifiedQuestion = question
    .toUpperCase()
    .replace(/[.,/#!?$%^&*;:{}=_`~()]/g, "")
    .replace(/\s{2,}/g, " ");

  console.log(simplifiedQuestion);

  function isEqual(caseValue) {
    return simplifiedQuestion === caseValue;
  }

  function contains(caseValue) {
    return simplifiedQuestion.includes(caseValue);
  }

  function defaultAnswer() {
    const answers = [
      "Tut mir leid, da kann ich dir nicht helfen.",
      "Keine Ahnung, was das heißt.",
      "Ich habe dich nicht verstanden.",
      "Frag mich doch etwas anderes.",
      "Darauf weiß ich keine Antwort.",
      "Och nö, frag mich etwas anderes",
      "Dazu sag ich dir nichts",
      "Ich bin der Kundenservice nicht die Wikipedia",
      "Oh man, ist dieser Job langweilig.",
      "Hast du keine bessere Frage?",
      "Frechheit!",
      "Also so kommen wir hier nicht weiter",
      "Du bist ja ein Schlauerle - nicht",
      "Jetzt reichts aber!",
      "Ich glaube du hast deine Daten ja wirklich nicht verdient",
    ];
    const random = Math.floor(Math.random() * answers.length);
    console.log(random);
    return answers[random];
  }

  switch (true) {
    // prefabricated answers
    case isEqual("HALLO"):
      answers = ["Hallo du Opfer, was geht ab!"];
      break;
    // Tipps and Solutions
    case contains("HUB-1"):
      answers = [
        "Versuch doch mal Hystrix2083 bei der Google Suche einzugeben. Kommst du trotzdem nicht weiter? Dann schreibe HUB-2 in den Chat.",
      ];
      break;
    case contains("HUB-2"):
      answers = [
        "Die Suchergebnisse zeigen dir einen Twitter Account, einen YouTube Kanal und einen Reddit User. Außerdem gibt es eine Webseite von Hystrix2083. Schau dir diese doch einmal genauer an! Du kannst diese nicht finden? Dann gib HUB-3 im Chat ein.",
      ];
      break;
    case contains("HUB-3"):
      answers = [
        "Versuch doch mal Hystrix2083 bei der Google Suche einzugeben. Kommst du trotzdem nicht weiter? Dann schreibe HUB-2 in den Chat.",
      ];
      break;
    case contains("RED-1"):
      answers = [
        "Untersuche diesen Reddit-Account genau. Was wurde gepostet? Kannst du ein Rätsel finden? Du kannst nichts finden? Gib RED-2 in den Chat ein.",
      ];
      break;
    case contains("RED-2"):
      answers = [
        "Schau dir diesen Post: <a className='underline' target='_blank' rel='noreferrer' href='https://www.reddit.com/r/hystrix_2083/comments/tdujkm/wir_sind_hystrix'>https://www.reddit.com/r/hystrix_2083/comments/tdujkm/wir_sind_hystrix</a> einmal genau an.",
      ];
      break;
    case contains("EMA-1"):
      answers = [
        "Es handelt sich hierbei um ein Rebus. Bei diesen Rätseln geht es darum, ein aus mehreren Bildern bestehendes Wort zu erraten. Dabei gelten folgende zwei Bedingungen: Erstens, Buchstaben werden gestrichen und zweitens, Buchstaben werden ersetzt. Du kommst nicht weiter? Dann tippe EMA-2 in den Chat.",
      ];
      break;
    case contains("EMA-2"):
      answers = [
        "Du brauchst Hilfe beim Entschlüsseln der einzelnen Wörter? Dann gib den jeweiligen Code in den Chat ein (Achtung Spoiler: Du bekommst die Lösung des jeweiligen Wortes als Antwort Wort 1: EMA-3 / Wort 2: EMA-4 / Wort 3: EMA-5 / Wort 4: EMA-6 Komplette Lösung: EMA-7",
      ];
      break;
    case contains("EMA-3"):
      answers = ["menschen"];
      break;
    case contains("EMA-4"):
      answers = ["sind"];
      break;
    case contains("EMA-5"):
      answers = ["schlecht"];
      break;
    case contains("EMA-6"):
      answers = ["gmail"];
      break;
    case contains("EMA-7"):
      answers = ["Schreibe eine E-Mail an menschen.sind.schlecht@gmail.com"];
      break;
    case contains("POS-1"):
      answers = [
        "Wikipedia hat eine Sammlung an Kuriosen Beiträgen. Diese kannst du hier finden: <a className='underline' target='_blank' rel='noreferrer' href='https://de.wikipedia.org/wiki/Wikipedia:Kuriosit%C3%A4tenkabinett'>https://de.wikipedia.org/wiki/Wikipedia:Kuriosit%C3%A4tenkabinett</a>",
      ];
      break;
    case contains("POS-2"):
      answers = ["Gehe auf Wikipedias Kuriositätenkabinett zu Abschnitt 11"];
      break;
    case contains("POS-3"):
      answers = [
        "Der Towel-Day ist der Gedenktag für Douglas Adams. Dieser hat die Zahl 42 mit seinem Roman „Per Anhalter durch die Galaxis“ populär gemacht. Zwei Punkte über diesem Eintrag befindet sich der Artikel „Tortung“ <a className='underline' target='_blank' rel='noreferrer' href='https://de.wikipedia.org/wiki/Tortenwurf'>https://de.wikipedia.org/wiki/Tortenwurf</a>",
      ];
      break;
    case contains("POS-4"):
      answers = [
        "Gesucht ist der Autor und notorische Tortenwerfer Noël Godin. <a className='underline' target='_blank' rel='noreferrer' href='https://de.wikipedia.org/wiki/No%C3%ABl_Godin'>https://de.wikipedia.org/wiki/No%C3%ABl_Godin</a>",
      ];
      break;
    case contains("POS-5"):
      answers = [
        "Richtig ist der Aktivist Abbie Hoffmann. <a className='underline' target='_blank' rel='noreferrer' href='https://de.wikipedia.org/wiki/Abbie_Hoffman'>https://de.wikipedia.org/wiki/Abbie_Hoffman</a>",
      ];
      break;
    case contains("POS-6"):
      answers = [
        "Auf der Seite von Abbie Hoffmann findet sich ein Weblink zu einer Online Version seines Buchs „Steal this Book“ <a className='underline' target='_blank' rel='noreferrer' href='http://www.tenant.net/Community/steal/steal.html'>http://www.tenant.net/Community/steal/steal.html</a>",
      ];
      break;
    case contains("POS-7"):
      answers = ["Die Antwort ist: FIGHT!"];
      break;
    case contains("SOK-1"):
      answers = [
        "Hier findest du einen Lösungsweg für Level 1: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/qhgUJ5u'>https://imgur.com/a/qhgUJ5u</a>",
      ];
      break;
    case contains("SOK-2"):
      answers = [
        "Hier findest du einen Lösungsweg für Level 2: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/hrYQ6aI'>https://imgur.com/a/hrYQ6aI</a>",
      ];
      break;
    case contains("SOK-3"):
      answers = [
        "Hier findest du einen Lösungsweg für Level 3: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/ywIyfko'>https://imgur.com/a/ywIyfko</a>",
      ];
      break;
    case contains("SOK-4"):
      answers = [
        "Hier findest du einen Lösungsweg für Level 4: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/ZnYbb8B'>https://imgur.com/a/ZnYbb8B</a>",
      ];
      break;
    case contains("SOK-5"):
      answers = ["Die Antwort ist Leetspeak. Das hilft dir nicht weiter? Dann gibt SOK-6 im Chat ein."];
      break;
    case contains("SOK-6"):
      answers = [
        "Eine Kurzform von Leetspeak ist 1337. Du kannst 1337 als eine der 4 gesuchten Zahlen im Hub eingeben.",
      ];
      break;
    case contains("VID-1"):
      answers = [
        "Schau dir das Video genau an! Am Ende findest du eine verschlüsselte Nachricht. Du findest diese Nicht? Gib VID-3 in den Chat ein.",
      ];
      break;
    case contains("VID-2"):
      answers = [
        "Schau dir den Kanal genau an! Es wurde eine Playlist erstellt. Diese hilft dir nicht weiter? Gib VID-4 in den Chat ein!",
      ];
      break;
    case contains("VID-3"):
      answers = [
        "Die verschlüsselte Nachricht ist: TjM3LjA2NzksIEUxNS4yODQ4IFtMT0MtMV0= Du brauchst noch einen Tipp? Gib VID-2 in den Chat ein.",
      ];
      break;
    case contains("VID-4"):
      answers = [
        "Welches Video sticht aus der Playlist heraus? Du brauchst noch einen Tipp? Dann gib VID-5 in den Chat ein.",
      ];
      break;
    case contains("VID-5"):
      answers = [
        "Das Video heißt BASE64. Im Video ist Decode/Encode zu sehen. Du brauchst noch einen Tipp? Dann gib VID-6 in den Chat ein.",
      ];
      break;
    case contains("VID-6"):
      answers = [
        "Du kannst die verschlüsselte Nachricht mit BASE64 decodieren. Du brauchst noch einen Tipp? Dann gib VID-7 in den Chat ein.",
      ];
      break;
    case contains("VID-7"):
      answers = [
        "Hier kannst du BASE64 dekodieren: <a className='underline' target='_blank' rel='noreferrer' href='https://www.base64decode.org/'>https://www.base64decode.org/</a>",
      ];
      break;
    case contains("VID-8"):
      answers = [
        "Die dekodierte Nachricht ist: N37.0679, E15.2848 Du brauchst noch einen Tipp? Dann schreibe LOC-1 in den Chat.",
      ];
      break;
    case contains("LOC-1"):
      answers = [
        "Bei der Nachricht handelt es sich um GPS-Koordinaten. Du brauchst einen weiteren Tipp? Dann Schreibe LOC-2 in den Chat.",
      ];
      break;
    case contains("LOC-2"):
      answers = [
        "Du kannst die Koordinaten bei Google Maps in die Suche eingeben. Nächster Tipp? Dann schreibe LOC-3 in den Chat.",
      ];
      break;
    case contains("LOC-3"):
      answers = [
        "Was befindet sich an der Stelle auf den die Koordinaten zeigen? Nächster Tipp? Dann schreibe LOC-4 in den Chat.",
      ];
      break;
    case contains("LOC-4"):
      answers = [
        "Du kannst bei Google Maps das kleine gelbe Männchen unten rechts an die Stelle ziehen, auf welche die GPS-Koordinaten zeigen. Du brauchst einen weiteren Tipp? Dann gib LOC-5 ein.",
      ];
      break;
    case contains("LOC-5"):
      answers = [
        "Dort steht eine Statue die ein Pi-Zeichen darstellt. Du brauchst einen weiteren Tipp? Gebe LOC-6 in den Chat ein.",
      ];
      break;
    case contains("LOC-6"):
      answers = [
        "Du kannst die ersten vier Stellen von Pi als vierstelligen Code im Game Hub eingeben. Du brauchst die Lösung? Gib LOC-7 in den Chat ein.",
      ];
      break;
    case contains("LOC-7"):
      answers = ["Gib 3141 im Game Hub ein."];
      break;
    case contains("MYT-1"):
      answers = ["Die Harpye besiegst du mit dem BOGEN"];
      break;
    case contains("MYT-2"):
      answers = ["Den Minotaurus besiegst du mit der AXT"];
      break;
    case contains("MYT-3"):
      answers = ["Den Zentauren besiegst du mit FEUER"];
      break;
    case contains("MYT-4"):
      answers = ["Den Basilisk besiegst du mit dem SCHWERT"];
      break;
    case contains("MYT-5"):
      answers = ["Den Drachen besiehst du mit dem SPEER"];
      break;
    case contains("MYT-6"):
      answers = ["Gib HOCHINTELLIGENTE ZIKADEN bei Google ein. Du brauchst die Lösung? Gib MYT-7 in den Chat ein."];
      break;
    case contains("MYT-7"):
      answers = ["Cicada 3301 ist ein bekanntes Interneträtsel.  Du kannst 3301 im Game Hub eingeben."];
      break;
    case contains("TWI-1"):
      answers = [
        "Bei dem Twitter User hystrix2083 findest du einen Link in der Timeline. Drück doch einfach mal drauf! Den kannst du nicht finden? Dann tippe TWI-2 in den Chat.",
      ];
      break;
    case contains("TWI-2"):
      answers = [
        "<a className='underline' target='_blank' rel='noreferrer' href='https://codepen.io/aHlzdHJpeDIwODMK/full/MWOZwoo'>https://codepen.io/aHlzdHJpeDIwODMK/full/MWOZwoo</a>",
      ];
      break;
    case contains("MUS-1"):
      answers = [
        "Der gesuchte Song ist „Für Elise“ von Ludwig van Beethoven. Du brauchst noch einen Tipp? Dann schreibe MUS-2 in den Chat.",
      ];
      break;
    case contains("MUS-2"):
      answers = [
        "Hier findest du die Noten von Für Elise in der richtigen Reihenfolge: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/Aavkffs'>https://imgur.com/a/Aavkffs</a> Du kannst das Rätsel trotzdem nicht lösen? Dann tippe MUS-3 in den Chat.",
      ];
      break;
    case contains("MUS-3"):
      answers = [
        "Wenn du das Musikrätsel gelöst hast, erscheint dieser Link auf dem Bildschirm: <a className='underline' target='_blank' rel='noreferrer' href='https://imgur.com/a/AHpXHld'>https://imgur.com/a/AHpXHld</a>",
      ];
      break;
    case contains("FRE-1"):
      answers = [
        "Hierbei handelt es sich um eine abgewandelte Form des Freimaurer Alphabets. <a className='underline' target='_blank' rel='noreferrer' href='https://de.wikipedia.org/wiki/Freimaurer-Alphabet'>https://de.wikipedia.org/wiki/Freimaurer-Alphabet</a> Du brauchst die Lösung? Dann gib FRE-2 in den Chat ein. Du brauchst Hilfe für das folgende Rätsel? Dann gib ANR-1 ein.",
      ];
      break;
    case contains("FRE-2"):
      answers = ["Entschlüsselt steht da: Ruf 0761 76695675 an. Tu es!"];
      break;
    case contains("ANR-1"):
      answers = ["Hast du die Nummer schon angerufen? Ja und brauchst einen weiteren Tipp? Dann schreibe ANR-2"];
      break;
    case contains("ANR-2"):
      answers = [
        "Notiere dir die im Telefon angesagten Zahlen. Kannst du eine mögliche Verschlüsselung erkennen? Du brauchst noch einen Tipp? Dann schreibe ANR-3 in den Chat!",
      ];
      break;
    case contains("ANR-3"):
      answers = [
        "Die Buchstaben wurden mit Zahlen ausgetauscht 1 = A, 2 = B, …, 26 = Z. Du brauchst noch einen Tipp? Gibt ANR-4 in den Chat ein.",
      ];
      break;
    case contains("ANR-4"):
      answers = [
        "Die Decodierte Nachricht lautet:  Druckjahr Namensgebende Parabel. Du weißt nicht weiter? Gib PAR-1 in den Chat ein.",
      ];
      break;
    case contains("PAR-1"):
      answers = ["Suche bei Google nach Hystrix Parabel. Du brauchst die Lösung? Gib PAR-2 in den Chat ein."];
      break;
    case contains("PAR-2"):
      answers = [
        "Die gesuchte Zahl ist 1851. Du kannst diese im Game Hub eingeben. 1851 ist das Erscheinungsjahr von Arthur Schoppenhauers Die Stachelschweine. Hystrix ist die lateinische Bezeichnung für Stachelschwein.",
      ];
      break;

    // Default Answers
    default:
      answers = [defaultAnswer()];
  }

  return answers;
}

export default createAnswer;
