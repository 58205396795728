import { useNavigate } from "react-router-dom";

import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

import outro from "../../assets/outro.mp4";

function Intro() {
  const navigate = useNavigate();

  function onEnd() {
    navigate("/end");
  }

  return (
    <div>
      <VideoPlayer video={outro} onEnd={onEnd} />
    </div>
  );
}

export default Intro;
