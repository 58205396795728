import clsx from "clsx";

function Button({ children, small, medium, large, icon, className, ...rest }) {
  const classes = clsx({
    "font-medium shadow bg-white text-black rounded-xl": true,
    "transform hover:shadow-md": true,
    "text-xl py-4 px-8": large,
    "text-lg py-2 px-4": medium,
    "text-base py-1 px-4": small,
  });

  return (
    <button
      className={`
        ${classes}
        ${className ? className : ""}
        `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
