import auth from "../auth.js";

const url = "https://bombig-escape.de/wp-json/lmfwc/v2/licenses/activate/";

async function deactivateCode(code) {
  const response = await fetch(url + code, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
    redirect: "follow",
  });
  const data = await response.json();
  const isSuccess = data.success;

  if (isSuccess) {
    return "ok";
  }
}

export default deactivateCode;
