import clsx from "clsx";

function Headline({ children, tag = "h1", size, className, ...rest }) {
  const Tag = tag;
  const classes = clsx({
    "font-bold leading-tight lg:leading-tight": true,
    "text-md lg:text-xl": size === "s",
    "text-lg lg:text-1xl 3xl:text-2xl": size === "m",
    "text-xl lg:text-2xl 3xl:text-3xl": size === "l",
    "text-2xl lg:text-3xl 3xl:text-4xl": size === "xl",
  });

  return (
    <Tag className={`${classes} ${className ? className : ""}`} {...rest}>
      {children}
    </Tag>
  );
}

export default Headline;
