function Typing() {
  return (
    <div className="w-24 h-10 relative flex justify-center items-center p-4 bg-gray-300 rounded-full">
      <div className="float-left w-3 h-3 mr-1 bg-gray-500 rounded-full animate-[loadingFade_1s_infinite] "></div>
      <div className="float-left w-3 h-3 mr-1 bg-gray-500 rounded-full animate-[loadingFade_1s_infinite_200ms]"></div>
      <div className="float-left w-3 h-3 mr-1 bg-gray-500 rounded-full animate-[loadingFade_1s_infinite_400ms]"></div>
    </div>
  );
}

export default Typing;
