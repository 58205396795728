import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import CodeContext from "../../utils/codeContext.js";

import Headline from "../../components/Headline/Headline.js";
import Paragraph from "../../components/Paragraph/Paragraph.js";
import Button from "../../components/Button/Button.js";

import hacktivism from "../../assets/hacktivism.svg";
import background from "../../assets/welcome-back.jpg";

import validateCode from "../../api/validateCode/validateCode.js";

function Welcome() {
  const context = useContext(CodeContext);

  const [notice, setNotice] = useState("");
  const navigate = useNavigate();

  function handleChangeInput(event) {
    context.setCode(event.target.value.toUpperCase());
  }

  function createNotice(notice) {
    const noticeElem = document.getElementById("notice");
    setNotice(notice);
    noticeElem.classList.remove("opacity-0");
    noticeElem.classList.add("opacity-100");
    window.setTimeout(() => {
      noticeElem.classList.remove("opacity-100");
      noticeElem.classList.add("opacity-0");
      setNotice("");
    }, 5000);
  }

  async function handleCodeCheck() {
    const response = await validateCode(context.code);
    if (response === "ok") {
      navigate("intro");
    } else if (response === "used") {
      createNotice("Code wurde bereits verwendet.");
    } else {
      createNotice("Code ist ungültig.");
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleCodeCheck();
    }
  }

  const backgroundStyle = {
    backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.3), 
          rgba(0, 0, 0, 0.3)
        ), url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  };

  return (
    <div style={backgroundStyle} className="min-h-screen text-white flex flex-col items-center justify-center">
      <main className="max-w-3xl flex flex-col items-center justify-center font-mono mx-4">
        <img src={hacktivism} alt="Hacktivism" className="w-full mb-6" />
        <Headline size="m" className="mb-4 text-center">
          wie dich Misantropen lieben lernen
        </Headline>
        <Paragraph className="text-center mb-4">
          Zum spielen benötigst du einen Gamecode. Falls du keinen Code hast kannst du diesen{" "}
          <a className="mb-2 underline" href="https://www.bombig.net/escape-room-online">
            hier kaufen
          </a>
          . Sonst gib bitte hier den Code ein und drücke auf Spiel starten.
        </Paragraph>
        <input
          value={context.code}
          onChange={handleChangeInput}
          onKeyPress={handleKeyPress}
          className="text-black py-3 px-0 mb-4 font-mono text-center text-2xl tracking-widest"
          maxLength="8"
        />
        <Button
          className="bg-transparent border-white border-2 rounded-none text-white uppercase text-xl mb-4"
          onClick={handleCodeCheck}
          medium
        >
          Spiel Starten
        </Button>
        <a href="https://www.bombig.net/escape-room-online">
          <Paragraph className="text-center underline">Kein Code? Hier kaufen.</Paragraph>
        </a>
        <div
          id="notice"
          className="bg-white h-10 w-80 mt-4 z-50 flex items-center justify-center opacity-0 text-black transition-all"
        >
          <Paragraph className="text-center"> {notice} </Paragraph>
        </div>
      </main>
    </div>
  );
}

export default Welcome;
