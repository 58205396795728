function Message({ message }) {
  const { text, time, sender } = message;
  const html = `${text} <span class="text-gray-500">${time}</span>`;
  const isUser = sender === "user";

  return (
    <div className={`w-full flex mb-5 break-words ${isUser ? "justify-start" : "justify-end"}`}>
      <div className={`text-black rounded-xl p-4 max-w-xl ${isUser ? "bg-slate-200" : "bg-amber-200"}`}>
        <p dangerouslySetInnerHTML={{ __html: html }}></p>
      </div>
    </div>
  );
}

export default Message;
