import { useState } from "react";

import { Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Paragraph from "../../components/Paragraph/Paragraph";
import Headline from "../../components/Headline/Headline";
import Divider from "../../components/Divider/Divider";
import Chat from "../../components/Chat/Chat";
import Info from "../../components/Info/Info";
import Rules from "../../components/Rules/Rules";

import background from "../../assets/background.jpg";
import logo from "../../assets/logo.png";
import bars from "../../assets/bars.svg";

function Game() {
  const [botQueue, setBotQueue] = useState(["Hallo, wie kann ich dir helfen?"]);
  const [messages, setMessages] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function openMenu() {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    } else {
      setMenuIsOpen(true);
    }
  }

  const location = useLocation();

  return (
    <div
      style={{ backgroundImage: `url("${background}")` }}
      className={`min-h-screen h-full lg:h-screen w-screen bg-center bg-cover 3xl:p-16 lg:p-8 p-4 pt-16 text-white font-mono flex flex-row items-center justify-center gap-4 xl:gap-12
      ${location.pathname === "/game/hilfe" ? "h-screen" : ""}`}
    >
      <div className="w-full h-12 bg-my-red fixed top-0 flex justify-center items-center lg:hidden">
        <img src={bars} alt="menu" onClick={openMenu} className="w-10 cursor-pointer" />
      </div>
      <aside
        className={`${
          menuIsOpen ? "-translate-x-0 lg:-translate-x-0" : "-translate-x-full lg:-translate-x-0"
        } lg:flex lg:w-1/3 lg:static fixed w-full top-10 h-screen lg:h-full transition-all bg-my-red z-50 border-solid border-2 border-my-red rounded-xl flex-col`}
      >
        <div className="p-8 pb-0 flex flex-col items-center">
          <img src={logo} alt="logo" className="w-full mb-8" />
          <Headline tag="h2" size="xl" className="text-center mb-4">
            <a href="https://hystrix2083.org/" target="_blank" rel="noreferrer">
              Hystrix2083
            </a>
          </Headline>
          <Divider className="mb-4" />
          <Paragraph className="text-center">
            Menschen rauben, lügen und töten. So ungebildet und beschränkt. Für Ihre Taten bedienen Sie sich den
            Errungenschaften einiger weniger. Das halten wir auf. <br /> <br />
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/watch?v=NIAuAg3pjaY"
            >
              MEHR IM MANIFEST
            </a>
          </Paragraph>
        </div>
        <ul className="p-8 h-auto lg:h-full items-center lg:items-start flex flex-col justify-end underline">
          <li className="mb-2">
            <Link to="/game/" onClick={() => setMenuIsOpen(false)}>
              Home
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/game/regeln" onClick={() => setMenuIsOpen(false)}>
              Regeln
            </Link>
          </li>
          <li className="mb-2">
            <Link to="/game/hilfe" onClick={() => setMenuIsOpen(false)}>
              Hilfe
            </Link>
          </li>
        </ul>
      </aside>
      <main
        className={`
      h-full w-full flex flex-col p-8 bg-my-red-half border-solid border-2 border-my-red rounded-xl
      ${location.pathname === "/game/hilfe" ? "p-0 lg:p-8" : ""}
      `}
      >
        <Routes>
          <Route path="/" element={<Info />} />
          <Route
            path="/hilfe"
            element={
              <Chat botQueue={botQueue} setBotQueue={setBotQueue} messages={messages} setMessages={setMessages} />
            }
          />
          <Route path="/regeln" element={<Rules />} />
        </Routes>
      </main>
    </div>
  );
}

export default Game;
